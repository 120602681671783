import React, { ReactElement } from 'react';
import { TailSpin } from 'react-loader-spinner';

const SPINNER_COLOR = '#000';
const SPINNER_DIMENSION = '60';
const SPINNER_RADIUS = '1';

// The spinner is taken from https://mhnpd.github.io/react-loader-spinner/docs/components/tail-spin/
const Spinner = (): ReactElement => (
    <TailSpin
        ariaLabel="tail-spin-loading"
        color={SPINNER_COLOR}
        height={SPINNER_DIMENSION}
        radius={SPINNER_RADIUS}
        width={SPINNER_DIMENSION}
    />
);

Spinner.displayName = 'Spinner';

export default Spinner;
