import React from 'react';
import {
    FiAlertCircle as ToastErrorIcon,
    FiCheckCircle as ToastSuccessIcon,
    FiInfo as ToastDefaultIcon,
} from 'react-icons/fi';

import { NOTIFICATION_VARIANTS, NotificationIconProps } from './types';

const NotificationIcon = ({ className, variant = NOTIFICATION_VARIANTS.default }: NotificationIconProps) => {
    switch (variant) {
        case NOTIFICATION_VARIANTS.success:
            return <ToastSuccessIcon className={className} />;
        case NOTIFICATION_VARIANTS.error:
            return <ToastErrorIcon className={className} />;
        default:
            return <ToastDefaultIcon className={className} />;
    }
};

export default NotificationIcon;
