'use client';

import { startTransition } from 'react';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { useVeniaRouter } from '@/utils/veniaRouter';
import { useProgress } from '@/components/MainLayout/hooks/useProgress';

const useTakeMeHome = (): (() => void) => {
    const { startLoading } = useProgress();
    // TO BE REMOVED AFTER FULL SSR RELEASE
    const router = useVeniaRouter();
    const resourceUrl = useResourceUrl();

    return () => {
        startTransition(() => {
            startLoading();
            router.push(resourceUrl('/'));
        });
    };
};

export default useTakeMeHome;
