import { gql } from '@apollo/client';
import store from '@/lib/store';

const { user } = store.getState();
const appliedStoreCredit = user.isSignedIn
    ? `applied_store_credit {
         applied_balance {
             currency
             value
         }
         current_balance {
          currency
          value
         }
        enabled
     }`
    : ``;

export const AppliedStoreCreditFragment = gql`
  fragment AppliedStoreCreditFragment on Cart {
    id
    ${appliedStoreCredit}
}
`;
