import React from 'react';
import Icon from './icon';

import type { NotificationProps } from './types';
import { NOTIFICATION_VARIANTS } from './types';

import clsx from 'clsx';
import classes from './notification.module.scss';

const Notification = ({ children, className, variant = NOTIFICATION_VARIANTS.default }: NotificationProps) => {
    const rootClassName = clsx(
        className,
        variant === NOTIFICATION_VARIANTS.default && classes.default,
        variant === NOTIFICATION_VARIANTS.error && classes.error,
        variant === NOTIFICATION_VARIANTS.success && classes.success,
    );

    const iconClassName = clsx(
        variant === NOTIFICATION_VARIANTS.default && classes.notificationIconDefault,
        variant === NOTIFICATION_VARIANTS.error && classes.notificationIconError,
        variant === NOTIFICATION_VARIANTS.success && classes.notificationIconSuccess,
    );

    return (
        <div className={rootClassName}>
            <Icon variant={variant} className={iconClassName} />
            <div className={classes.notificationContent}>{children}</div>
        </div>
    );
};

export const MaybeErrorNotification = ({ message }: { message?: string }) =>
    message ? <Notification variant="error">{message}</Notification> : null;

export default Notification;
