import React, { ReactElement, useMemo } from 'react';

import mergeClasses from '@/utils/Object/shallowMerge';
import { ConfigurableProductOptions } from '@/types/product';
import defaultClasses from './productOptions.module.scss';

interface IProductOptionsProps {
    classes?: {
        [className: string]: string;
    };
    options: ConfigurableProductOptions[];
}

const ProductOptions = (props: IProductOptionsProps): ReactElement | null => {
    const { options = [] } = props;
    const classes = mergeClasses(defaultClasses, props.classes);

    const displayOptions = useMemo(
        () =>
            options.map(({ option_label, value_label }) => {
                const key = `${option_label}${value_label}`;
                const optionLabelString = `${option_label}:`;

                return (
                    <div key={key} className={classes.option}>
                        <dt className={classes.label}>{optionLabelString}</dt>
                        <dd>{value_label}</dd>
                    </div>
                );
            }),
        [options],
    );

    if (!displayOptions.length) {
        return null;
    }

    return <dl className={classes.wrapper}>{displayOptions}</dl>;
};

ProductOptions.displayName = 'ProductOptions';

export default ProductOptions;
