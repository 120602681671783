import { useCallback } from 'react';
import { useAppContext } from '@/lib/context';
import type { Money } from '@/types/product';

export type Prices = {
    priceExclTax: Money;
    priceInclTax: Money;
};

export type GetPriceWithOrWithoutTax = ({ priceExclTax, priceInclTax }: Prices) => Money;

export interface IUseTaxIncludedInPriceOutput {
    getPriceWithOrWithoutTax: GetPriceWithOrWithoutTax;
}

export const useIsTaxIncludedInPrice = (): IUseTaxIncludedInPriceOutput => {
    const [{ storeConfig }] = useAppContext();
    const { isPriceIncludesTax } = storeConfig;

    const getPriceWithOrWithoutTax = useCallback(
        ({ priceExclTax, priceInclTax }: Prices) => (isPriceIncludesTax ? priceInclTax : priceExclTax),
        [isPriceIncludesTax],
    );

    return {
        getPriceWithOrWithoutTax,
    };
};
