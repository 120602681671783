'use client';

import React, { ReactNode, ReactPortal, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
    children: ReactNode;
}

const Portal = ({ children }: IPortalProps): ReactPortal | null => {
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => setMounted(true), []);

    return mounted ? createPortal(<>{children}</>, document.body) : null;
};

export default Portal;
