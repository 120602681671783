import { gql } from '@apollo/client';

import { MiniCartFragment } from '@/components/MiniCart/api/miniCartFragments.gql';

export const getDonationStoreConfigQuery = gql`
    query getDonationStoreConfig {
        storeConfig {
            donation_minimum_amount
            donation_maximum_amount
            donation_round_up_to
            id
            store_code
        }
    }
`;

export const getDonationProductsQuery = gql`
    query getDonationProducts {
        donationProducts {
            items {
                uid
                name
                sku
                small_image {
                    url
                }
                short_description {
                    html
                }
            }
        }
    }
`;

export const getCartTotalQuery = gql`
    query getPriceSummary($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            prices {
                grand_total {
                    currency
                    value
                }
            }
        }
    }
`;

export const addDonationProductToCartMutation = gql`
    mutation addDonationProductToCart($cartId: String!, $donationAmount: Float!, $quantity: Float!, $sku: String!) {
        addDonationProductsToCart(
            input: {
                cart_id: $cartId
                cart_items: [{ data: { quantity: $quantity, sku: $sku }, donation_amount: $donationAmount }]
            }
        ) @connection(key: "addDonationProductsToCart") {
            cart {
                id
                ...MiniCartFragment
            }
        }
    }
    ${MiniCartFragment}
`;

export const removeItemMutation = gql`
    mutation RemoveItemForMiniCart($cartId: String!, $itemId: Int!) {
        removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $itemId }) @connection(key: "removeItemFromCart") {
            cart {
                id
                ...MiniCartFragment
            }
        }
    }
    ${MiniCartFragment}
`;
