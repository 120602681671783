import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './mask.module.scss';

interface IMaskProps {
    classes?: {
        [className: string]: string;
    };
    dismiss?: (() => void) | undefined;
    isActive: boolean;
}

const Mask = ({ classes: propsClasses, dismiss, isActive }: IMaskProps): ReactElement => {
    const t = useTranslations('global');

    const classes = mergeClasses(defaultClasses, propsClasses);
    const className = isActive ? classes.root_active : classes.root;

    return <button className={className} onClick={dismiss} type="reset" aria-label={t('close')} role="button" />;
};

Mask.displayName = 'Mask';

export default Mask;
