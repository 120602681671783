import { useMediaQuery } from 'react-responsive';

// Important notice: Please don't use to conditionally render any components, if it breaks the rule of not having unified mobile and desktop application structure
// ex: isMobile ? <Component/> : null;
// reference: https://developers.google.com/search/mobile-sites/mobile-first-indexing#content-is-same
interface IMobileProps {
    considerLandscape?: boolean;
    maxWidth?: string;
    maxWidthLandscape?: string;
}

const useIsMobile = (props: IMobileProps = {}): boolean => {
    const { considerLandscape = false, maxWidth = '48rem', maxWidthLandscape = '64rem' } = props;
    const isWidthCorrect = useMediaQuery({ maxWidth });
    // pointer device only
    const isPointerFine = useMediaQuery({ query: '(pointer: fine)' });
    const isWidthLandscapeCorrect = useMediaQuery({ maxWidth: maxWidthLandscape, orientation: 'landscape' });

    // Inverted logic here also allows for pointer agnostic crawlers
    return (isWidthCorrect || (considerLandscape && isWidthLandscapeCorrect)) && !isPointerFine;
};

export default useIsMobile;
