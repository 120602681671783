import React, { ReactNode, ReactElement } from 'react';
import { FiAlertTriangle as AlertTriangleIcon } from 'react-icons/fi';
import { TranslationValues, useTranslations } from 'next-intl';

import clsx from 'clsx';
import defaultClasses from './message.module.scss';

interface IMessageProps {
    children?: ReactNode;
    className?: string;
    dataCustomId?: string;
    error?: string | { id: string; values: TranslationValues };
}

const Message = ({ children, className, dataCustomId, error }: IMessageProps): ReactElement | null => {
    const t = useTranslations('validation');
    const classes = clsx(className, error && defaultClasses.error);

    const translatedErrorMessage = error ? (typeof error === 'string' ? t(error) : t(error.id, error.values)) : '';
    const errorMessage = translatedErrorMessage || children;

    return errorMessage ? (
        <p className={classes} data-custom-id={dataCustomId}>
            {error && <AlertTriangleIcon />}
            {errorMessage}
        </p>
    ) : null;
};

Message.displayName = 'Message';

export default Message;
