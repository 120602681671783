import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import { Field, useField } from 'formik';
import Message from '@/components/FormMessage';
import mergeClasses from '@/utils/Object/shallowMerge';
import type { ITextInputProps } from './types';

import clsx from 'clsx';
import defaultClasses from './textInput.module.scss';

const OptionalLabel = () => {
    const t = useTranslations('field');

    return <>{t('optional')}</>;
};

export const TextInput = (props: ITextInputProps): ReactElement => {
    const {
        animateNotice,
        classes: propClasses,
        dataCustomId,
        field,
        hasError,
        isDonationInput,
        isOptionalNextToTitle,
        isPlaceholderItalic,
        isRequired,
        isValidateOnBlur = false,
        label,
        message,
        notice,
        supplementLabel,
        wrapperClassName,
        ...rest
    } = props;

    const [, meta] = useField(field);
    const classes = mergeClasses(defaultClasses, propClasses);
    const spanSingleColumnFieldsArrayNames = ['firstname', 'firstName', 'lastname', 'lastName', 'city', 'postcode'];

    const error = (isValidateOnBlur || meta.touched) && meta.error;
    const success = !error && (meta.value || meta.initialValue);

    const textInputClassName = clsx(
        classes.textInput,
        (error || hasError) && classes.error,
        meta.value && classes.hasValue,
        !isRequired && classes.isPadded,
        isPlaceholderItalic && classes.placeholderItalic,
        success && classes.success,
    );
    const rootClassName = clsx(
        classes.root,
        wrapperClassName,
        isDonationInput && error && classes.donationInputErrorWrapper,
        spanSingleColumnFieldsArrayNames.includes(field) && classes.spanSingleColumn,
    );

    return (
        <div className={rootClassName}>
            <div className={classes.wrapper}>
                <Field
                    {...rest}
                    aria-required={isRequired}
                    className={textInputClassName}
                    data-hj-suppress={true}
                    name={field}
                    field={field}
                />
                <label className={classes.label} htmlFor={field}>
                    {label || field}
                    {isOptionalNextToTitle && <OptionalLabel />}
                </label>
                <fieldset className={classes.fieldset} aria-hidden="true">
                    <legend className={classes.legend}>
                        <span>
                            {label || field}
                            {isOptionalNextToTitle && <OptionalLabel />}
                        </span>
                    </legend>
                </fieldset>
                {!isRequired && !isOptionalNextToTitle ? (
                    <sup className={classes.optionalSupplement}>
                        <OptionalLabel />
                    </sup>
                ) : supplementLabel ? (
                    <sup className={classes.supplement}>{supplementLabel}</sup>
                ) : null}
            </div>
            {error || message ? (
                <Message error={error || ''} dataCustomId={dataCustomId}>
                    {message}
                </Message>
            ) : null}
            {notice && (
                <span
                    key={notice.toString()}
                    className={animateNotice ? classes.fieldNoticeAnimate : classes.fieldNotice}
                    data-custom-id="animate-notice"
                >
                    {notice}
                </span>
            )}
        </div>
    );
};

TextInput.displayName = 'TextInput';

export default TextInput;
