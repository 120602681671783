import { useCallback, useEffect } from 'react';
import { useGetDonationProductsData } from './useGetDonationProductsData';
import BrowserPersistence from '@vaimo/donation-products/src/helpers/simplePersistence';
import type {
    DonationInputValidation,
    IHandleAddToCartInput,
    IUseDonationProducts,
    IUseDonationProductsProps,
    Product,
} from '@vaimo/donation-products/src/types';

const DEFAULT_DONATION_QUANTITY = 1;
const DONATION_AMOUNT = 'donation-amount';

export const useDonationProducts = ({
    cartId,
    hasDonationProductsInCart,
}: IUseDonationProductsProps): IUseDonationProducts => {
    const {
        addDonationProductToCart,
        currentCartTotal,
        donationMaximumAmount,
        donationMinimumAmount,
        donationProducts,
        donationRoundUpTo,
        isAddDonationProductCalled,
        isAddDonationProductLoading,
        removeItemFromCart,
    } = useGetDonationProductsData({ cartId });

    const storage = new BrowserPersistence();
    const userInputDonationAmount = storage.getItem(DONATION_AMOUNT);

    const isValidDonationAmount = (value: string): DonationInputValidation => {
        const enteredAmount = Number(value);
        const isEnteredAmountValid = (enteredAmount: number) =>
            enteredAmount &&
            !isNaN(enteredAmount) &&
            donationMinimumAmount &&
            donationMaximumAmount &&
            enteredAmount >= donationMinimumAmount &&
            enteredAmount < donationMaximumAmount;

        if (isEnteredAmountValid(enteredAmount)) {
            return;
        }

        if (!value) {
            return 'donationProducts.emptyInputError';
        }

        if (value && isNaN(enteredAmount)) {
            return 'donationProducts.invalidInputValidationMessage';
        }

        if (donationMinimumAmount && enteredAmount < donationMinimumAmount) {
            return {
                id: 'donationProducts.inputValidationMessage',
                values: { value: donationMinimumAmount },
            };
        }

        if (donationMaximumAmount && enteredAmount > donationMaximumAmount) {
            return 'donationProducts.inputMaximumAmountValidationMessage';
        }
    };

    const handleAddDonationToCart = useCallback(
        async ({ donationProduct, formValues }: IHandleAddToCartInput) => {
            const { donationAmount } = formValues || {};
            storage.setItem(DONATION_AMOUNT, donationAmount);
            try {
                await addDonationProductToCart({
                    variables: {
                        cartId,
                        donationAmount,
                        quantity: DEFAULT_DONATION_QUANTITY,
                        sku: donationProduct?.sku,
                    },
                });
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
            }
        },
        [addDonationProductToCart, cartId],
    );

    const handleDonationProductAutoRemoval = useCallback(
        async (productList: Product[]) => {
            try {
                for (const product of productList) {
                    await removeItemFromCart({
                        variables: {
                            cartId,
                            itemId: product.id,
                        },
                    });
                }
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
            }
        },
        [cartId, removeItemFromCart],
    );

    useEffect(() => {
        if (!hasDonationProductsInCart && !isAddDonationProductCalled) {
            storage.removeItem(DONATION_AMOUNT);
        }
    }, [isAddDonationProductCalled, hasDonationProductsInCart]);

    return {
        currentCartTotal,
        donationProducts,
        donationRoundUpTo,
        handleAddDonationToCart,
        handleDonationProductAutoRemoval,
        isAddDonationProductLoading,
        isValidDonationAmount,
        userInputDonationAmount,
    };
};
