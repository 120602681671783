import React, { ReactElement } from 'react';
import Price from '@/components/Price';

import clsx from 'clsx';
import classes from './productPrice.module.scss';

export interface ProductPriceProps {
    className?: string;
    currency: string;
    hasDiscount: boolean;
    maxPrice: number;
    minPrice: number;
}

const ProductPrice = ({ className, currency, hasDiscount, maxPrice, minPrice }: ProductPriceProps): ReactElement => (
    <div className={clsx(classes.root, className)}>
        <span>
            <Price value={minPrice} currencyCode={currency} />
        </span>
        {minPrice < maxPrice && (
            <span className={hasDiscount ? classes.oldPrice : classes.maxPrice}>
                <Price value={maxPrice} currencyCode={currency} />
            </span>
        )}
    </div>
);

export default ProductPrice;
