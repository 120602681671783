import { useEffect, useState } from 'react';
import { useAppContext } from '@/lib/context';
import { useLocale } from 'next-intl';

type PricePartsType = {
    type: string;
    value: string | number;
};

const formatPrice = (pattern: PricePartsType[], currency: string, locale: string, value: number): string => {
    if (!pattern) {
        return new Intl.NumberFormat(locale, {
            currency,
            style: 'currency',
        }).format(value);
    }

    const amount = new Intl.NumberFormat(locale, {
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).formatToParts(value);

    const amountStart = pattern.findIndex(({ type }) => type === 'integer');
    const amountEnd = pattern.findLastIndex(({ type }) => type === 'fraction');

    pattern.splice(amountStart, amountEnd - amountStart + 1, ...amount);

    return pattern.map((item) => item.value).join('');
};

export const usePrice = ({ currencyCode, value }: { currencyCode: string; value: number }) => {
    const [{ storeConfig }] = useAppContext();
    const [currentPrice, setCurrentPrice] = useState(value);
    const [oldPrice, setOldPrice] = useState(value);
    const locale = useLocale();

    useEffect(() => {
        setCurrentPrice((int) => {
            setOldPrice(int);

            return value;
        });
    }, [value]);

    const joinedPriceString = formatPrice(
        !locale.startsWith('en') && storeConfig.patternPrice,
        currencyCode,
        locale.startsWith('en') ? 'en-US' : locale,
        value,
    );

    return {
        currentPrice,
        joinedPriceString,
        oldPrice,
    };
};
