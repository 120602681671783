import React, { ReactElement } from 'react';
import Portal from '@/components/Portal';
import Mask from '@/components/Mask';
import Spinner from '@/components/Spinner';
import type { NotificationModalProps } from './types';

import clsx from 'clsx';
import classes from './notification.module.scss';

const NotificationModal = ({ children, className, isActive, isShowLogo }: NotificationModalProps): ReactElement => (
    <Portal>
        <aside className={isActive ? classes.notificationModalActive : classes.notificationModal}>
            <Mask isActive={isActive} />
            <div className={clsx(className, classes.notificationModalContent)}>
                {isShowLogo && (
                    <div className={classes.notificationModalIcon}>
                        <Spinner />
                    </div>
                )}
                {children}
            </div>
        </aside>
    </Portal>
);

NotificationModal.displayName = 'NotificationModal';

export default React.memo(NotificationModal);
