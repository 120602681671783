import { gql } from '@apollo/client';
import { DiscountSummaryFragment } from './discountSummary.gql';
import { ShippingSummaryFragment } from './shippingSummary.gql';
import { TaxSummaryFragment } from './taxSummary.gql';

export const GrandTotalFragment = gql`
    fragment GrandTotalFragment on CartPrices {
        grand_total {
            currency
            value
        }
    }
`;

export const PriceSummaryFragment = gql`
    fragment PriceSummaryFragment on Cart {
        id
        applied_coupons {
            code
        }
        errors {
            code
            message
        }
        items {
            id
            uid
            quantity
            product {
                id
                uid
                name
                sku
                price {
                    regularPrice {
                        amount {
                            currency
                            value
                        }
                    }
                }
                ... on ConfigurableProduct {
                    price_range {
                        minimum_price {
                            final_price {
                                currency
                                value
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                    }
                }
            }
        }
        ...ShippingSummaryFragment
        prices {
            ...TaxSummaryFragment
            ...DiscountSummaryFragment
            ...GrandTotalFragment
            subtotal_excluding_tax {
                currency
                value
            }
            subtotal_including_tax {
                currency
                value
            }
            discount_breakdown {
                label
                amount
                simple_action
            }
        }
    }
    ${DiscountSummaryFragment}
    ${GrandTotalFragment}
    ${ShippingSummaryFragment}
    ${TaxSummaryFragment}
`;
