'use client';

import React, { ReactElement } from 'react';
import { usePrice } from './hooks/usePrice';
import TextTransition from 'react-text-transition';

type IPriceProps = {
    classes?: {
        animatePrice?: string;
        animatePriceUpdating?: string;
    };
    currencyCode: string;
    isAnimated?: boolean;
    isUpdating?: boolean;
    textOnUpdating?: string;
    value: number;
};

const Price = ({
    classes = {},
    currencyCode,
    isAnimated = false,
    isUpdating = false,
    textOnUpdating,
    value,
}: IPriceProps): ReactElement => {
    const { currentPrice, joinedPriceString, oldPrice } = usePrice({ currencyCode, value });

    return isAnimated ? (
        <TextTransition
            className={isUpdating && classes.animatePriceUpdating ? classes.animatePriceUpdating : classes.animatePrice}
            springConfig={{ mass: 1, tension: 170 }}
            inline
            direction={currentPrice > oldPrice ? 'up' : 'down'}
        >
            {isUpdating && textOnUpdating ? textOnUpdating : joinedPriceString}
        </TextTransition>
    ) : (
        <>{joinedPriceString}</>
    );
};

export default Price;
