'use client';

import React, { ReactElement, useId } from 'react';
import clsx from 'clsx';
import Collapsible, { type CollapsibleProps } from 'react-collapsible-ssr';
import classes from './styles/collapsibleSSR.module.scss';

interface ICollapsibleSSR extends CollapsibleProps {
    isAlwaysOpenOnDesktop?: boolean;
}

const CollapsibleSSR = ({ children, isAlwaysOpenOnDesktop, ...props }: ICollapsibleSSR): ReactElement => {
    const id = useId();

    return (
        <Collapsible
            {...props}
            contentElementId={`collapsible-content-${id}`}
            contentOuterClassName={clsx(
                isAlwaysOpenOnDesktop && classes.collapsibleOuterContent,
                props.contentOuterClassName,
            )}
            triggerElementProps={{ ...props.triggerElementProps, id: `collapsible-trigger-${id}` }}
        >
            {children}
        </Collapsible>
    );
};

CollapsibleSSR.displayName = 'CollapsibleSSR';

export default CollapsibleSSR;
