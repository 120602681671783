import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import {
    addDonationProductToCartMutation,
    getCartTotalQuery,
    getDonationProductsQuery,
    getDonationStoreConfigQuery,
    removeItemMutation,
} from './donationProducts.gql';
import type {
    CartTotalResponse,
    DonationProductsConfigResponse,
    DonationProductsResponse,
    IUseGetDonationProductsData,
    IUseGetDonationProductsDataProps,
} from '@vaimo/donation-products/src/types';

export const useGetDonationProductsData = ({
    cartId,
}: IUseGetDonationProductsDataProps): IUseGetDonationProductsData => {
    const { data: donationProductsData } = useQuery<DonationProductsResponse>(getDonationProductsQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const donationProducts = useMemo(() => donationProductsData?.donationProducts?.items || [], [donationProductsData]);

    const { data: donationStoreConfigData } = useQuery<DonationProductsConfigResponse>(getDonationStoreConfigQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !donationProducts?.length,
    });

    const { data: cartTotalData } = useQuery<CartTotalResponse>(getCartTotalQuery, {
        fetchPolicy: 'cache-and-network',
        skip: !cartId || !donationProducts?.length,
        variables: {
            cartId,
        },
    });

    const [addDonationProductToCart, { called: isAddDonationProductCalled, loading: isAddDonationProductLoading }] =
        useMutation(addDonationProductToCartMutation);

    const [removeItemFromCart] = useMutation(removeItemMutation);

    const {
        donation_maximum_amount: donationMaximumAmount,
        donation_minimum_amount: donationMinimumAmount,
        donation_round_up_to: donationRoundUpTo,
    } = donationStoreConfigData?.storeConfig || {};

    const currentCartTotal = useMemo(() => cartTotalData?.cart?.prices?.grand_total, [cartTotalData]);

    return {
        addDonationProductToCart,
        cartId,
        currentCartTotal,
        donationMaximumAmount,
        donationMinimumAmount,
        donationProducts,
        donationRoundUpTo,
        isAddDonationProductCalled,
        isAddDonationProductLoading,
        removeItemFromCart,
    };
};
