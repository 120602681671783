import React, { ReactElement, ReactNode } from 'react';
import { FiHelpCircle as HelpCircleIcon, FiInfo as InfoIcon } from 'react-icons/fi';
import mergeClasses from '@/utils/Object/shallowMerge';
import CollapsibleSSR from '@/components/CollapsibleSSR';

import defaultClasses from './tooltip.module.scss';

interface TooltipProps {
    children: ReactNode;
    classes?: {
        tooltipCollapsible?: string;
        tooltipCollapsibleOpened?: string;
        tooltipContentWrapper?: string;
        tooltipTrigger?: string;
    };
    content?: ReactNode;
    dataCustomId?: string;
    isOpen?: boolean;
    isSmall?: boolean;
}

const Tooltip = (props: TooltipProps): ReactElement => {
    const { children, content, dataCustomId, isOpen } = props;
    const classes = mergeClasses(defaultClasses, props.classes);

    const trigger = (
        <div className={classes.tooltipTrigger}>
            {children}
            <HelpCircleIcon size={18} />
        </div>
    );

    const triggerOpen = (
        <div className={classes.tooltipTrigger}>
            {children}
            <InfoIcon size={18} />
        </div>
    );

    return content ? (
        <CollapsibleSSR
            openedClassName={classes.tooltipCollapsibleOpened}
            contentInnerClassName={classes.tooltipCollapsible}
            easing={'ease-in-out'}
            open={isOpen}
            trigger={trigger}
            triggerWhenOpen={triggerOpen}
            transitionTime={100}
        >
            <div className={classes.tooltipContentWrapper} data-custom-id={dataCustomId}>
                {content}
            </div>
        </CollapsibleSSR>
    ) : (
        <>{children}</>
    );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
