'use client';

import React, { ReactElement, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { Form, Formik } from 'formik';
import type { DonationProduct, Product } from '@vaimo/donation-products/src/types';
import {
    hasDonationProductsInCart,
    hasOnlyDonationProductsInCart,
    isAddDonationProductHidden,
    useDonationProducts,
} from '@vaimo/donation-products';
import TextInput from '@/components/TextInput';
import Button from '@/components/Button';
import RichContent from '@/components/RichContent';
import OptimizedImage from '@/components/OptimizedImage';
import Tooltip from '@/components/Tooltip';

import classes from './addDonationProductToCart.module.scss';

interface AddDonationProductToCartProps {
    cartId: string;
    productList: Product[] | null;
}

const AddDonationProductToCart = ({ cartId, productList }: AddDonationProductToCartProps): ReactElement | null => {
    const {
        currentCartTotal,
        donationProducts,
        donationRoundUpTo,
        handleAddDonationToCart,
        handleDonationProductAutoRemoval,
        isAddDonationProductLoading,
        isValidDonationAmount,
        userInputDonationAmount,
    } = useDonationProducts({ cartId, hasDonationProductsInCart: hasDonationProductsInCart(productList) });
    const { currency } = currentCartTotal || {};

    useEffect(() => {
        if (!productList?.length || !hasOnlyDonationProductsInCart(productList)) {
            return;
        }

        handleDonationProductAutoRemoval(productList);
    }, [handleDonationProductAutoRemoval, productList]);

    const t = useTranslations('donationProducts');

    const donateButtonLabelLocalized = isAddDonationProductLoading ? t('addingDonationToCart') : t('donate');

    if (!donationProducts?.length || isAddDonationProductHidden(productList)) {
        return null;
    }

    return donationProducts.map((donationProduct: DonationProduct) => (
        <div className={classes.root} key={donationProduct.sku} data-mt-type="donation-product-container">
            <Tooltip
                content={<RichContent html={donationProduct.short_description?.html} />}
                classes={{
                    tooltipTrigger: classes.donationProductTooltip,
                }}
            >
                {donationProduct.name}
            </Tooltip>
            <div className={classes.content} data-mt-type="donation-product-content">
                <div className={classes.imageWrapper}>
                    <OptimizedImage
                        alt={donationProduct.name}
                        className={classes.image}
                        fill
                        src={donationProduct.small_image?.url}
                    />
                </div>
                {donationRoundUpTo && (
                    <Formik
                        initialValues={{ donationAmount: (userInputDonationAmount || donationRoundUpTo)?.toString() }}
                        onSubmit={(formValues) => {
                            handleAddDonationToCart({
                                donationProduct,
                                formValues,
                            });
                        }}
                    >
                        <Form className={classes.form}>
                            <TextInput
                                field="donationAmount"
                                id="donationAmount"
                                isRequired
                                label={t('yourDonation')}
                                supplementLabel={currency}
                                isDonationInput
                                type={'tel'}
                                validate={isValidDonationAmount}
                            />
                            <Button
                                disabled={isAddDonationProductLoading}
                                isFullWidth
                                type="submit"
                                variant={'tertiary'}
                            >
                                {donateButtonLabelLocalized}
                            </Button>
                        </Form>
                    </Formik>
                )}
            </div>
        </div>
    ));
};

AddDonationProductToCart.displayName = 'DonationProduct';

export default AddDonationProductToCart;
