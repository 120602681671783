import type { ConfigurableCartItem, ConfigurableProductOptions } from '@/types/product';

export default function configuredVariant(
    configured_options?: ConfigurableProductOptions[],
    product?: ConfigurableCartItem['product'],
) {
    if (!configured_options || !product?.variants) return;

    const optionUids = configured_options
        .map(({ configurable_product_option_value_uid }) => configurable_product_option_value_uid)
        .sort()
        .toString();

    return product.variants
        .map((variant) => {
            const variantUids = variant.attributes
                .map((attribute) => attribute.uid)
                .sort()
                .toString();

            return variantUids === optionUids && variant.product;
        })
        .find(Boolean);
}
