import type { Product } from '@vaimo/donation-products/src/types';

const isConfigurableProduct = (product: Product): boolean =>
    ['ConfigurableProduct', 'ConfigurableCartItem'].includes(product?.__typename);

export const isDonationProduct = (product: Product): boolean =>
    ['DonationProduct', 'DonationCartItem'].includes(product?.__typename);

export const isAddDonationProductHidden = (productList: Product[] | null): boolean =>
    productList?.some(isDonationProduct) || !productList?.some(isConfigurableProduct);

export const hasOnlyDonationProductsInCart = (productList: Product[] | null): boolean =>
    productList?.length === productList?.filter(isDonationProduct).length;

export const hasDonationProductsInCart = (productList: Product[] | null): boolean =>
    !!productList?.some(isDonationProduct);
