import { ReactNode } from 'react';

export enum NOTIFICATION_VARIANTS {
    error = 'error',
    default = 'default',
    success = 'success',
}

export type NotificationModalProps = {
    children?: ReactNode;
    className?: string;
    isActive: boolean;
    isShowLogo: boolean;
};

export type NotificationProps = {
    children?: ReactNode;
    className?: string;
    isVisible?: boolean;
    variant?: `${keyof typeof NOTIFICATION_VARIANTS}`;
};

export type NotificationIconProps = {
    className?: string;
    variant?: `${keyof typeof NOTIFICATION_VARIANTS}`;
};
